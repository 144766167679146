@import '../node_modules/bootstrap/dist/css/bootstrap-grid.min.css';

@font-face {
    font-family: "Almarai";
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local("almarai"),
    url("./fonts/almarai/Almarai-Light.ttf") format("truetype");
}

/* Almarai-regular - latin */
@font-face {
    font-family: "Almarai";
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local("almarai"),
    url("./fonts/almarai/Almarai-Regular.ttf") format("truetype");
}

/* Almarai-bold - latin */
@font-face {
    font-family: "Almarai";
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local("almarai"),
    url("./fonts/almarai/Almarai-Bold.ttf") format("truetype");
}

/* Almarai-Extra-bold - latin */
@font-face {
    font-family: "Almarai";
    font-style: normal;
    font-display: swap;
    font-weight: 800;
    src: local("almarai"),
    url("./fonts/almarai/Almarai-ExtraBold.ttf") format("truetype");
}

